import React from 'react'
import "./footer.scss"

const Footer = () => {
  return (
    <footer>
      <span>&copy; Trevor Wallis 2022</span>
    </footer>
  )
}

export default Footer